import * as cheerio from "cheerio";
function getScriptVersion(source) {
    return (/(\d{1,3}\.\d{1,3}\.\d{1,3})/.exec(source))?.[0];
}
export function replaceGsapScriptWithSameVersion($, newScript, defaultVersion) {
    const scripts = $("script[src*=\"gsap\"]").toArray();
    if (scripts.length) {
        scripts.forEach((script) => {
            const src = $(script).attr("src") ?? "";
            const version = getScriptVersion(src);
            let replaceVersion = defaultVersion;
            if (version && parseInt(version.split(".")[0]) > 2) {
                replaceVersion = version;
            }
            const replaceScript = newScript.replace("[VERSION]", replaceVersion);
            $(script).replaceWith(replaceScript);
        });
    }
    else {
        $("head").append(newScript.replace("[VERSION]", defaultVersion));
    }
}
