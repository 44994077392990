<template>
    <div class="qa-single-annotation-comment">
        <qa-confirmation-modal
            v-if="confirmCommentDeletionModalIsVisible"
            :is-doing-action="isDeletingComment"
            @cancel="toggleConfirmCommentDeletionModal"
            @confirm="deleteComment"
        >
            <template #title>Are you sure you want to delete the comment?</template>
            <template #content>
                <p>Deleting a comment is permanent: it can not be recovered.</p>
            </template>
            <template #confirmButtonContent>Delete comment</template>
        </qa-confirmation-modal>
        <div class="qa-single-annotation-comment__header">
            <div class="qa-single-annotation-comment__info">
                <p class="qa-single-annotation-comment__user-name" :title="comment.author">
                    {{ comment.author }}
                </p>
                <p class="qa-single-annotation-comment__date">
                    {{ formattedTimestamp }}
                </p>
            </div>
            <div
                v-if="(isLoggedInUsersComment || userCanManageOtherUsersComments) && !annotationIsResolved"
                class="qa-single-annotation-comment__action-items"
            >
                <square-button
                    v-if="isLoggedInUsersComment"
                    :has-inverse-hover="!isEditing"
                    :is-solid="isEditing"
                    size="small"
                    @click.stop="toggleIsEditing"
                >
                    <Icon type="md-create" />
                </square-button>
                <square-button
                    v-if="isLoggedInUsersComment || userCanManageOtherUsersComments"
                    has-inverse-hover
                    size="small"
                    @click.stop="toggleConfirmCommentDeletionModal"
                >
                    <Icon type="md-trash" data-testid="qa-single-annotation-comment-delete-icon" />
                </square-button>
            </div>
        </div>
        <qa-annotation-input-form
            v-if="isEditing"
            :content="sanitizedCommentContent"
            :is-saving="isUpdatingComment"
            @cancel="toggleIsEditing"
            @save="updateComment"
        />
        <!--
      The content in the following element is whitespace sensitive in order to
      display the comment as intended by the user.
    -->
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-else class="qa-single-annotation-comment__body" v-html="commentPreview"></div>
    </div>
</template>

<script>
import SquareButton from "@/components/common/SquareButton";
import QaAnnotationInputForm from "@/components/Qa/QaAnnotationInputForm";
import QaConfirmationModal from "@/components/Qa/QaConfirmationModal";
import { deleteAnnotationComment, updateAnnotationComment } from "@/services/Qa";
import { formatTimestamp, sanitizeHTML } from "@/utils";
import mentionableMixin from "@/mixins/mentionableMixin";

export default {
    components: {
        QaAnnotationInputForm,
        QaConfirmationModal,
        SquareButton
    },
    mixins: [mentionableMixin],
    props: {
        annotationIsResolved: {
            required: true,
            type: Boolean
        },
        comment: {
            required: true,
            type: Object
        }
    },
    data() {
        return {
            confirmCommentDeletionModalIsVisible: false,
            isDeletingComment: false,
            isEditing: false,
            isUpdatingComment: false
        };
    },
    computed: {
        commentPreview() {
            const regexMentions = [...this.validMentions].sort().reverse().join("|");
            const regex = new RegExp(`\\B(${regexMentions})(\\s|$)`, "g");
            return this.sanitizedCommentContent.replace(
                regex,
                match => `<span class="mentionable-tag">${match}</span>`
            );
        },

        formattedTimestamp() {
            return formatTimestamp(this.comment.created);
        },
        isLoggedInUsersComment() {
            return this.$store.state.auth.me.id === this.comment.author;
        },

        sanitizedCommentContent() {
            return sanitizeHTML(this.comment.content);
        },

        userCanManageOtherUsersComments() {
            return this.$auth.userCan(this.$auth.Actions.CanManageOtherUsersComments);
        }
    },
    methods: {
        async deleteComment() {
            this.isDeletingComment = true;
            try {
                await deleteAnnotationComment(this.comment._id);
                this.$emit("commentDeleted", this.comment);
                this.toggleConfirmCommentDeletionModal();
                this.$snackbar.success("Comment deleted.");
            } catch (err) {
                this.$snackbar.error(
                    "There was an unexpected error and the comment was unable to be deleted. Please try again in a few moments."
                );
            }
            this.isDeletingComment = false;
        },
        toggleIsEditing() {
            this.isEditing = !this.isEditing;
        },
        toggleConfirmCommentDeletionModal() {
            this.confirmCommentDeletionModalIsVisible = !this.confirmCommentDeletionModalIsVisible;
        },
        async updateComment({ content, mentions }) {
            this.isUpdatingComment = true;
            try {
                const updatedComment = await updateAnnotationComment(this.comment._id, content, mentions);
                this.toggleIsEditing();
                this.$emit("commentUpdated", updatedComment);
                this.$snackbar.success("Comment updated.");
            } catch (err) {
                this.$snackbar.error(
                    "There was an unexpected error and the comment was unable to be updated. Please try again in a few moments."
                );
            }
            this.isUpdatingComment = false;
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.qa-single-annotation-comment {
    border-bottom: 1px solid $grey3;
    margin: 0 (0 - $spacing) 0 0;
    padding: $spacing $spacing $spacing-small;

    &:last-child {
        border-bottom: none;
    }
}

.qa-single-annotation-comment__action-items {
    display: flex;
}

.qa-single-annotation-comment__body {
    font-size: $font-size-small;
    margin: 0 0 $spacing-small;
    white-space: pre-wrap;
}

.qa-single-annotation-comment__date {
    color: $grey5;
    font-size: $font-size-small;
}

.qa-single-annotation-comment__edit-button {
    margin: 0 0 0 $spacing-small;
}

.qa-single-annotation-comment__edit-buttons-container {
    display: flex;
    justify-content: flex-end;
    padding: $spacing-small 0 0 0;
}

.qa-single-annotation-comment__edit-container {
    margin: 0 0 $spacing-small;
}

.qa-single-annotation-comment__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 0 $spacing-small;
}

.qa-single-annotation-comment__info {
    overflow: hidden;
    padding: 0 $spacing-smaller 0 0;
}

.qa-single-annotation-comment__user-name {
    font-size: $font-size-small;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>
