<template>
    <hox-modal class="download-modal" :max-width-px="700" resizable @close="close">
        <template #header>Download</template>
        <template>
            <div class="download-modal__select-wrapper">
                Format
                <download-platform-select
                    v-model="platform"
                    :show-html-options="showHtmlOptions"
                    :show-psd-options="showPsdOptions"
                    :show-ae-options="showAeOptions"
                    data-testid="asset-download__format-select"
                    class="download-modal__select-platform"
                />
            </div>
            <Tabs v-if="platform" value="downloadOptions">
                <TabPane label="Download" name="downloadOptions">
                    <hox-alert v-if="requiresProfileId" :type="alertType">
                        <template #title>
                            <p v-if="hasProfileId">DC Campaign ProfileId</p>
                            <p v-else>Missing DC Campaign ProfileId</p>
                        </template>
                        <template #content>
                            <p v-if="!hasProfileId">
                                Before downloading your ads, set up your campaign feed in DoubleClick to get your
                                Profile ID and add it here:
                            </p>

                            <Input
                                v-model="dcProfileId"
                                class="download-modal__dc-profile-input"
                                :class="{
                                    'download-modal__dc-profile-input--error': hasProfileId && !isProfileIdValid
                                }"
                                placeholder="Double Click Profile Id"
                            />
                            <span
                                v-show="hasProfileId && !isProfileIdValid"
                                class="download-modal__dc-profile-input-error-message"
                            >
                                ProfileId needs to be a number
                            </span>
                        </template>
                    </hox-alert>

                    <hox-alert v-else-if="isFlashtalkingExcel" :type="alertTypeFlashtalking">
                        <template #title>
                            <p v-if="hasFlashtalkingCampaignName && hasFlashtalkingId">
                                FlashTalking Campaign (Creative Library) Name & Reference ID
                            </p>
                            <p v-else-if="hasFlashtalkingCampaignName">Missing FlashTalking Reference ID</p>
                            <p v-else-if="hasFlashtalkingId">Missing FlashTalking Campaign (Creative Library) Name</p>
                            <p v-else>Missing FlashTalking Campaign (Creative Library) Name & Reference ID</p>
                        </template>
                        <template #content>
                            <Input
                                v-model="flashtalkingCampaignName"
                                class="download-modal__dc-profile-input"
                                placeholder="FlashTalking Campaign (Creative Library) Name"
                            />
                            <Input
                                v-model="flashtalkingCampaignId"
                                class="download-modal__dc-profile-input"
                                :class="{
                                    'download-modal__dc-profile-input--error':
                                        hasFlashtalkingId && !isFlashtalkingIdValid
                                }"
                                placeholder="Reference ID"
                            />
                            <span
                                v-show="hasFlashtalkingId && !isFlashtalkingIdValid"
                                class="download-modal__dc-profile-input-error-message"
                            >
                                Reference ID needs to be a number
                            </span>
                            <div class="download-modal__options-wrapper">
                                <ul>
                                    <li>
                                        <Checkbox
                                            v-model="flashtalkingRemoveVersionNameDimensions"
                                            label="Strip Dimensions"
                                        >
                                            <h4>Strip Dimensions</h4>
                                        </Checkbox>
                                        <p>Strip Dimensions from Version Name</p>
                                    </li>
                                </ul>
                            </div>
                        </template>
                    </hox-alert>
                    <hox-alert v-else-if="isAEDownloading" type="info">
                        <template #title>
                            <p>This could take a while</p>
                        </template>
                        <template #content>
                            <p>Download links will be listed below as they become available.</p>
                        </template>
                    </hox-alert>
                    <hox-alert v-else-if="!aeDownloadLink" type="info">
                        <template #title>
                            <p>This could take a while</p>
                        </template>
                        <template #content>
                            <p>
                                You will be notified when they are ready to download and you will be able to find
                                download links in your notifications.
                            </p>
                        </template>
                    </hox-alert>
                    <div v-if="isAEDownloading">
                        <Spin medium />
                    </div>
                    <div v-else-if="aeDownloadLink">
                        <p>
                            <a :href="aeDownloadLink" download type="application/zip">Download zipped videos</a>
                        </p>
                    </div>

                    <div class="download-modal__options-wrapper">
                        <div v-if="isZipDownload">
                            <ul>
                                <template v-if="isFlashtalking">
                                    <li class="download-modal__short-input">
                                        <Checkbox v-model="ftClickTagCount" label="Click Tag Count">
                                            <h4>Click Tag Count</h4>
                                        </Checkbox>
                                        <p>Flashtalking Click tag count</p>

                                        <div class="download-modal__short-input-value">
                                            <Input v-model="ftClickTagCountValue" @input="onClickTagCountChange" />
                                        </div>
                                    </li>
                                    <li class="download-modal__long-input">
                                        <Checkbox v-model="ftBrowserExclusions" label="Browser Exclusions">
                                            <h4>Browser Exclusions</h4>
                                        </Checkbox>
                                        <p>Comma separated list of browsers</p>
                                        <div class="download-modal__long-input-value">
                                            <Input
                                                v-model="ftBrowserExclusionsValue"
                                                @input="onBrowserExclusionChange"
                                            />
                                        </div>
                                    </li>
                                </template>
                                <li>
                                    <Checkbox v-model="optimiseTextFiles" label="Optimise Files">
                                        <h4>Optimise Files</h4>
                                    </Checkbox>
                                    <p>Minify HTML, CSS and JS files used by the template</p>
                                </li>
                                <li>
                                    <Checkbox v-model="optimiseFontFiles" label="Optimise Fonts">
                                        <h4>Optimise Fonts</h4>
                                    </Checkbox>
                                    <p>Remove unused glyphs from font files used in the template</p>
                                </li>
                                <li>
                                    <Checkbox v-model="optimiseImages" label="Optimise Images">
                                        <h4>Optimise Images</h4>
                                    </Checkbox>
                                    <p>This may result in colour profile changes</p>
                                </li>
                                <li v-if="isGenericHTML">
                                    <Checkbox v-model="backupImage" label="Include backup images">
                                        <h4>Include backup images</h4>
                                    </Checkbox>
                                </li>
                            </ul>
                        </div>
                        <div v-else-if="isVideoDownload">
                            <ul>
                                <li class="download-modal__option-duration download-modal__short-input">
                                    <Checkbox
                                        v-model="manualDuration"
                                        label="Capture Audio"
                                        data-testid="download-video-runtime-checkbox"
                                    >
                                        <h4>Video runtime</h4>
                                    </Checkbox>
                                    <p>You can choose to manually specify the video length</p>
                                    <div
                                        class="download-modal__option-duration-value download-modal__short-input-value"
                                    >
                                        <Input
                                            v-model="manualDurationValue"
                                            placeholder="0.00"
                                            data-testid="download-video-runtime-value"
                                            @input="onDurationChange"
                                        >
                                            <template slot="append">
                                                <span>sec</span>
                                            </template>
                                        </Input>
                                    </div>
                                </li>
                                <li class="download-modal__option-fps">
                                    <Checkbox v-model="manualFps" label="FPS settings">
                                        <h4>Frame rate</h4>
                                    </Checkbox>
                                    <p>You can choose to manually specify the video framerate</p>
                                    <div class="download-modal__short-input-value">
                                        <Select v-model="manualFpsValue" size="small" @input="onFpsChange">
                                            <Option v-for="item in fpsOptions" :key="item" :value="item">
                                                {{ item }}
                                            </Option>
                                        </Select>
                                    </div>
                                </li>
                                <li>
                                    <Checkbox v-model="captureAudio" label="Capture Audio">
                                        <h4>Capture Audio</h4>
                                    </Checkbox>
                                    <p>Capture audio from the template and include it in the output video</p>
                                </li>
                                <li>
                                    <Checkbox v-model="highQuality" label="High Quality">
                                        <h4>Export as uncompressed</h4>
                                    </Checkbox>
                                    <p>You can then re-encode into another format manually</p>
                                </li>
                                <li>
                                    <Checkbox v-model="droppedFramesOptimisation" label="Optimise Video Frames">
                                        <h4>Optimise Video Frames</h4>
                                    </Checkbox>
                                    <p>
                                        Useful when the banner contains a video with the same fps as the output. Note
                                        that it will make the video generation slower.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </TabPane>
                <TabPane label="Changes overview" name="configs">
                    <download-modal-platform-details :platform="platform" />
                </TabPane>
            </Tabs>
        </template>

        <template #footer>
            <Button class="download-modal__button" data-testid="asset-download__button--cancel" @click="close">
                Cancel
            </Button>
            <Button
                class="download-modal__button"
                :disabled="isDownloadDisabled"
                type="primary"
                data-testid="asset-download__button--download"
                @click="processDownloads"
            >
                Download
            </Button>
        </template>
    </hox-modal>
</template>

<script>
// eslint-disable-next-line no-unused-vars, import/no-extraneous-dependencies
import * as Types from "shared-utils/types";
// eslint-disable-next-line import/no-extraneous-dependencies
import { TemplateType } from "shared-utils/enums/masterTemplate";

import { requestBatchRender } from "@/utils/ae";
import getAfterEffectsApiInfoQuery from "@/apollo/queries/v2/GetAfterEffectsApiInfo.gql";
import { MasterTemplateLibraryAction } from "@/store/modules/masterTemplateLibrary";
// @ts-ignore
import HoxModal from "@/components/Modal/Modal/Modal";
// @ts-ignore
import DownloadPlatformSelect from "@/components/Campaign/DownloadPlatformSelect";
// @ts-ignore
import HoxAlert from "@/components/common/Alert";
import { NotificationTypes } from "@/enums/notifications";
import { BatchDownloads, Platform, ZipDownloads } from "@/enums/platforms";
import editableMethodsMixin from "@/mixins/editableMethodsMixin";
import DeliverablesService from "@/services/Deliverables";
import { CampaignAction } from "@/store/modules/campaign";
import { JobsAction } from "@/store/modules/jobs";
import { JobStatusToNotificationStatus, JobNames } from "@/enums/jobs";
import { NotificationsAction, NotificationsGetter } from "@/store/modules/notifications";
import computedValuesToComputedOverwrites from "@/utils/computedValuesToComputedOverwrites";
import DownloadModalPlatformDetails from "@/components/DownloadModalPlatformDetails";

export default {
    components: {
        HoxAlert,
        HoxModal,
        DownloadPlatformSelect,
        DownloadModalPlatformDetails
    },
    mixins: [editableMethodsMixin],
    props: {
        deliverables: {
            required: true,
            type: Array
        },
        deliverableIdentifiers: {
            type: Array
        },
        showHtmlOptions: {
            required: true,
            type: Boolean
        },
        showPsdOptions: {
            required: true,
            type: Boolean
        },
        showAeOptions: {
            required: true,
            type: Boolean
        },
        libraryFiltersForQuery: {
            required: false,
            type: Object
        }
    },

    data() {
        return {
            captureAudio: true,
            highQuality: false,
            emailAddresses: [],
            firstDurationChange: true,
            manualDuration: false,
            manualDurationValue: "",
            ftClickTagCount: true,
            ftClickTagCountValue: 1,
            ftBrowserExclusions: true,
            ftBrowserExclusionsValue: "ie8, ie9, ie10, ie11, opera",
            manualFps: false,
            manualFpsValue: "60",
            droppedFramesOptimisation: false,
            optimiseFontFiles: true,
            optimiseImages: true,
            optimiseTextFiles: true,
            backupImage: false,
            platform: "",
            previewUrlsByIdHash: {},
            /** @type {string | null} */
            aeDownloadLink: null,
            isAEDownloading: false,
            /** @type {Object | null} */
            deliverablesService: null,
            fpsOptions: [24, 25, 30, 60],
            flashtalkingCampaignName: "",
            flashtalkingCampaignId: "",
            flashtalkingRemoveVersionNameDimensions: false
        };
    },
    computed: {
        alertType() {
            return this.isProfileIdValid ? "success" : "warning";
        },

        alertTypeFlashtalking() {
            return this.hasFlashtalkingCampaignName && this.isFlashtalkingIdValid ? "success" : "warning";
        },

        canDownloadDeliverable() {
            return this.$auth.userCan(this.$auth.Actions.CanDownloadDeliverable, {
                clientId: this.clientId,
                campaignId: this.campaignId
            });
        },

        clientId() {
            return this.$store.state.route.params.clientId;
        },

        dcProfileId: {
            get() {
                return this.$store.state.campaign.dcProfileId ? this.$store.state.campaign.dcProfileId.toString() : "";
            },
            /**
             * @param {string} dcProfileId
             */
            set(dcProfileId) {
                this.$store.dispatch(CampaignAction.SetDcProfileId, dcProfileId);
            }
        },

        hasProfileId() {
            return !!this.dcProfileId;
        },

        isDownloadDisabled() {
            return (
                (this.requiresProfileId && !this.isProfileIdValid) ||
                (this.isFlashtalkingExcel && (!this.hasFlashtalkingCampaignName || !this.isFlashtalkingIdValid)) ||
                this.platform === "" ||
                this.isAEDownloading
            );
        },

        isProfileIdValid() {
            return this.hasProfileId && this.dcProfileId.match(/^\d+$/);
        },

        isAEVideoDownload() {
            return this.showAeOptions && this.platform === BatchDownloads.Video;
        },

        isFlashtalkingIdValid() {
            return this.flashtalkingCampaignId && this.flashtalkingCampaignId.match(/^\d+$/);
        },

        isVideoDownload() {
            return !this.showAeOptions && this.platform === BatchDownloads.Video;
        },

        isZipDownload() {
            return (
                !this.isFlashtalkingAssets &&
                !this.isFlashtalkingExcel &&
                Object.values(ZipDownloads).includes(this.platform)
            );
        },

        // eslint-disable-next-line complexity
        platformOpts() {
            return {
                ...(this.isZipDownload && {
                    optimiseFontFiles: this.optimiseFontFiles,
                    optimiseImages: this.optimiseImages,
                    optimiseTextFiles: this.optimiseTextFiles,
                    ...(this.isFlashtalking && this.ftPlatformOpts),
                    ...(this.isGenericHTML && {
                        backupImage: this.backupImage
                    })
                }),
                ...(this.isVideoDownload && {
                    captureAudio: this.captureAudio,
                    highQuality: this.highQuality,
                    droppedFramesOptimisation: this.droppedFramesOptimisation,
                    ...(this.manualDuration && { duration: Number.parseFloat(this.manualDurationValue) }),
                    ...(this.manualFps && { fps: Number.parseFloat(this.manualFpsValue) })
                }),
                ...(this.requiresProfileId && { dcProfileId: parseInt(this.dcProfileId, 10) }),
                ...((this.isFlashtalkingAssets || this.isFlashtalkingExcel) && {
                    flashtalkingCampaignName: this.flashtalkingCampaignName,
                    flashtalkingCampaignId: +this.flashtalkingCampaignId,
                    flashtalkingRemoveVersionNameDimensions: this.flashtalkingRemoveVersionNameDimensions
                })
            };
        },

        ftPlatformOpts() {
            return {
                ...(this.ftClickTagCount && { clickTagCount: this.ftClickTagCountValue }),
                ...(this.ftBrowserExclusions && {
                    browserExclusion: this.ftBrowserExclusionsValue.split(",").map(s => s.trim())
                })
            };
        },

        requiresProfileId() {
            return this.platform === Platform.DoubleClickSV;
        },

        isFlashtalking() {
            return this.platform === Platform.Flashtalking;
        },

        isFlashtalkingExcel() {
            return this.platform === Platform.FlashtalkingExcel;
        },

        isFlashtalkingAssets() {
            return this.platform === Platform.FlashtalkingAssets;
        },

        isGenericHTML() {
            return this.platform === ZipDownloads.Generic;
        },

        hasFlashtalkingCampaignName() {
            return !!this.flashtalkingCampaignName;
        },

        hasFlashtalkingId() {
            return !!this.flashtalkingCampaignId;
        }
    },

    watch: {
        canDownloadDeliverable() {
            if (!this.canDownloadDeliverable) {
                this.$snackbar.warning("You do not have access to download deliverables");
                this.close();
            }
        },

        platform(newVal) {
            if (newVal === Platform.Flashtalking || newVal === ZipDownloads.Generic) {
                this.optimiseFontFiles = false;
                this.optimiseImages = false;
                this.optimiseTextFiles = false;
            } else {
                this.optimiseFontFiles = true;
                this.optimiseImages = true;
                this.optimiseTextFiles = true;
            }
        }
    },

    created() {
        this.deliverablesService = new DeliverablesService(this.$apollo);
    },

    apollo: {
        afterEffectsApiInfo: {
            query: getAfterEffectsApiInfoQuery,
            result({ data }) {
                this.$store.dispatch(MasterTemplateLibraryAction.SetAfterEffectsApiInfo, data.afterEffectsApiInfo);
            }
        }
    },

    methods: {
        /**
         * @param {string} notificationId
         * @param {Types.Deliverable} deliverable
         */
        addDeliverableDownloadFailureToNotification(notificationId, deliverable) {
            const notification = this.$store.getters[NotificationsGetter.GetItemById](notificationId);
            this.$store.dispatch(NotificationsAction.Update, {
                ...notification,
                deliverablesThatFailedToDownload: [...notification.deliverablesThatFailedToDownload, deliverable]
            });
        },

        /**
         * @param {string} notificationId
         * @param {Types.Deliverable} deliverable
         */
        addDeliverableWithDownloadLinkToNotification(notificationId, deliverable) {
            const notification = this.$store.getters[NotificationsGetter.GetItemById](notificationId);
            this.$store.dispatch(NotificationsAction.Update, {
                ...notification,
                deliverablesWithDownloadLink: [...notification.deliverablesWithDownloadLink, deliverable]
            });
        },

        close() {
            this.$emit("close");
        },

        onDurationChange() {
            if (this.firstDurationChange && this.manualDurationValue && !this.manualDuration) {
                this.manualDuration = true;
                this.firstDurationChange = false;
            }
        },

        onBrowserExclusionChange() {
            if (!this.ftBrowserExclusions) {
                this.ftBrowserExclusions = true;
            }
        },

        onClickTagCountChange() {
            if (!this.ftClickTagCount) {
                this.ftClickTagCount = true;
            }
        },

        onFpsChange() {
            if (!this.manualDuration) {
                this.manualFps = true;
            }
        },

        async processDownloads() {
            if (this.isAEVideoDownload && this.platform === BatchDownloads.Video) {
                try {
                    this.isAEDownloading = true;

                    this.$snackbar.info("Your ads are being prepared for download.");

                    const inputs = this.deliverables.reduce(
                        /**
                         * @param {import("@/utils/ae").RequestRenderInput[]} acc
                         * @param {Types.Deliverable} deliverable
                         */
                        (acc, deliverable) => {
                            if (deliverable.masterTemplate.adType !== TemplateType.AE) {
                                return acc;
                            }

                            const computedOverwrites = computedValuesToComputedOverwrites(deliverable.computedValues);

                            acc.push({
                                reportingLabel: deliverable.reportingLabel,
                                // @ts-ignore
                                aeInfo: deliverable.masterTemplate?.aeInfo,
                                computedOverwrites
                            });

                            return acc;
                        },
                        []
                    );

                    this.aeDownloadLink = await requestBatchRender({
                        clientId: this.clientId,
                        aeApiInfo: {
                            baseUrl: this.afterEffectsApiInfo.url,
                            apiKey: this.afterEffectsApiInfo.apiKey
                        },
                        inputs
                    });

                    this.$snackbar.success("Your ads are ready for download");

                    this.isAEDownloading = false;
                } catch {
                    this.$snackbar.error(
                        "There was an unexpected error while preparing your ads for download. Please try again in a few moments"
                    );

                    this.isAEDownloading = false;
                }
            } else {
                this.close();
                const jobNotificationMap = {
                    [JobNames.PUBFT_DOWNLOAD_EXCEL]: NotificationTypes.FlashtalkingDownloadExcel,
                    [JobNames.PUBFT_DOWNLOAD_ASSETS]: NotificationTypes.FlashtalkingDownloadAssets
                };

                try {
                    // @ts-ignore
                    const job = await this.deliverablesService?.downloadJob(
                        this.campaignId,
                        this.platform,
                        this.deliverables,
                        this.deliverableIdentifiers,
                        this.libraryFiltersForQuery,
                        this.platformOpts
                    );
                    const notificationId = await this.$store.dispatch(NotificationsAction.Add, {
                        message: job.message,
                        status: JobStatusToNotificationStatus[job.status],
                        type: jobNotificationMap[job.name] || NotificationTypes.DownloadJob,
                        job
                    });
                    this.$store.dispatch(JobsAction.SetNotificationIdByActiveJobId, {
                        jobId: job._id,
                        notificationId
                    });
                    this.$snackbar.info(
                        "Your ads are being prepared for download. Take a look at your notifications to check on progress"
                    );
                } catch (err) {
                    this.$snackbar.error(
                        "There was an unexpected error while preparing your ads for download. Please try again in a few moments"
                    );
                }
            }
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.download-modal__button {
    margin: 0 0 0 $spacing-small;
}

.download-modal__select-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 $spacing;
    width: 500px;
}

.download-modal__select-platform {
    flex: 1 0;
    width: 100%;
    margin-left: 20px;
}

.download-modal__dc-profile-input {
    margin-bottom: $spacing-small;

    &--error {
        border: 1px solid $error-color;

        .ivu-input:focus,
        .ivu-input:hover {
            border-color: $error-color;
        }
    }

    &-error-message {
        color: $error-color;
    }
}

.download-modal__options-wrapper {
    h4 {
        display: inline-block;
    }

    ul {
        list-style: none;

        li {
            margin-bottom: $spacing-semi-small;
        }
    }

    .ivu-checkbox {
        margin-right: $spacing-small;
    }

    p {
        font-size: $font-size-smaller;
        margin-left: 26px;
    }

    .download-modal__short-input {
        &-value {
            width: 78px;
            float: right;
            margin-top: -35px;
            height: 35px;
        }

        .ivu-input-group-append {
            background-color: transparent;
            border: 0 none;
        }
    }

    .download-modal__long-input {
        &-value {
            width: 220px;
            float: right;
            margin-top: -35px;
            height: 35px;
        }

        .ivu-input-group-append {
            background-color: transparent;
            border: 0 none;
        }
    }
}
</style>
