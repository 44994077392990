<template>
    <div class="container">
        <div v-if="isHtml">
            <p>
                <strong>
                    Assemble populates raw master templates with the necessary computed values, computed overwrites,
                    metadata and platform specific data and customizations.
                </strong>
            </p>
            <br />
            <p><strong>Changes made to the Hoxton data:</strong></p>
            <ol>
                <li>Set the 'platform' value with the one requested</li>
                <li>
                    Editable values in the manifest data are replaced with updated ones from the overwrites. Only
                    editables already present in the original manifest will be updated
                </li>
                <li>
                    If custom styling exists for the editables, we add it in the Hoxton data as well as add it as
                    'style' to the targeted elements
                </li>
                <li v-if="platform === 'flashtalking'">
                    All the 'editableGroups' from the Hoxton data are removed and editable tag type is converted from
                    'css' to 'text'
                </li>
                <li v-if="platform === 'opendc'">
                    A 'data-placeholder' attribute is added to each editable tag in the template
                </li>
            </ol>
            <br />
            <p><strong>Fonts</strong></p>
            <ol>
                <li>If custom fonts are added, a style tag is added to the head to load them</li>
            </ol>
            <br />
            <p><strong>Files and folders</strong></p>
            <ol>
                <li v-if="platform === 'flashtalking' || platform === 'generic'">
                    The irelevant media queries to the size of the banner are removed from the css file
                </li>
                <li v-if="platform === 'flashtalking' || platform === 'generic'">
                    The width and height properties are added to the body selector in the css file
                </li>
                <li>
                    <code>hoxton.json</code>
                    file is removed
                </li>
                <li>
                    <code>html5-static-wrapper</code>
                    folder is removed if exists
                </li>
                <li v-if="platform === 'opendc'">
                    Computed values are mapped to generate a template.json file in the format
                    <code>[computedValue.name]: type</code>
                    , where type can be "text," "image," or "video."
                    <br />
                    E.g.:
                    <code>{ "placeholders": { "text1": "text", "logo": "image", "videoContainer": "video" } }</code>
                </li>
                <li v-if="platform === 'generic'">
                    The exit function is amended with:
                    <code>function (eventName, exitURL) { window.open(window.clickTag); return; }</code>
                </li>
            </ol>
            <br />
            <p v-if="configs.head"><strong>Changes within the head tag</strong></p>
            <ul v-if="configs.head">
                <li v-if="configs.head.add">Tags added:</li>
                <ol v-if="configs.head.add">
                    <li v-for="(item, index) in configs.head.add" :key="index">
                        <code>{{ item }}</code>
                    </li>
                </ol>
                <li v-if="configs.head.replace">Tags added or replaced:</li>
                <ol v-if="configs.head.replace">
                    <li v-for="(item, index) in configs.head.replace" :key="index">
                        <code>{{ item.value }}</code>
                        is added. Existing tags matching the
                        <code>{{ item.selector }}</code>
                        selector will be replaced
                    </li>
                    <li v-if="platform === 'generic'">
                        The exit function is amended with:
                        <code>function (eventName, exitURL) { window.open(window.clickTag); return; }</code>
                    </li>
                </ol>
            </ul>

            <br />
            <p v-if="configs.body"><strong>Changes within the body tag</strong></p>
            <ul v-if="configs.body">
                <li v-if="configs.body.add">Tags added:</li>
                <ol v-if="configs.body.add">
                    <li v-for="(item, index) in configs.body.add" :key="index">
                        <code>{{ item }}</code>
                    </li>
                </ol>
            </ul>
        </div>
        <div v-else>
            <p>No changes apply to this platform.</p>
        </div>
    </div>
</template>
<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { config } from "dyce";
import { Platform } from "@/enums/platforms";

export default {
    props: {
        platform: {
            required: true,
            type: String
        }
    },
    data() {
        return {
            configs: {}
        };
    },
    computed: {
        isHtml() {
            switch (this.platform) {
                case Platform.DoubleClick:
                case Platform.DoubleClickSV:
                case Platform.Flashtalking:
                case Platform.Sizmek:
                case Platform.SizmekSV:
                case Platform.OpenDC:
                case "generic":
                    return true;
                default:
                    return false;
            }
        }
    },
    watch: {
        platform: {
            immediate: true,
            handler(newPlatform) {
                this.updateConfigs(newPlatform);
            }
        }
    },

    methods: {
        updateConfigs(newPlatform) {
            this.configs = config[newPlatform] ?? {};
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";
code {
    background-color: $grey1;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
}
ol,
ul {
    padding-left: 20px;
}

.container {
    height: 400px;
    overflow: auto;
    padding-right: 40px;
}
</style>
