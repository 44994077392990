<template>
    <resizable-campaign-banner
        class="library-banner"
        class-name="library-banner"
        :master-template="deliverable.masterTemplate"
        :max-height="maxHeight"
        :max-width="maxWidth"
        :scaling-factor="scalingFactor"
    >
        <template #bannerContainer="{ slotProps }">
            <Checkbox
                data-testid="campaign-banner-checkbox"
                :label="deliverable.idHash"
                :class="{ 'library-banner__banner-container-checkbox--resizable': slotProps.isResizable }"
            >
                <banner-preview
                    :banner="previewBannerAdapter(deliverable)"
                    :lazy-load="true"
                    :preview-values="previewValues(deliverable)"
                    :scaling-factor="slotProps.internalScalingFactor"
                    :ad-type="deliverable.masterTemplate.adType"
                    :is-resizable="slotProps.isResizable"
                    @BannerLoaded="onBannerLoaded"
                />
            </Checkbox>
        </template>

        <template #footer="{ slotProps, slotToggleResizeMode }">
            <div class="library-banner__footer">
                <set-banner-status-dropdown
                    v-if="$auth.userCan($auth.Actions.CanChangeDeliverableQAStatus, { clientId, campaignId })"
                    :deliverable="deliverable"
                    @statusHasBeenUpdated="emitStatusHasBeenUpdated"
                />
                <banner-status-indicator v-else :deliverable="deliverable" />
                <div class="library-banner__title">
                    <banner-info-poptip :deliverable="deliverable" />
                </div>
                <library-banner-action-items-dropdown
                    :is-html5="isHTML5"
                    :is-resizable="slotProps.isResizable"
                    @refreshBanner="refreshBanner"
                    @showDrawer="showDrawer"
                    @resizeMode="slotToggleResizeMode"
                />
            </div>
        </template>
    </resizable-campaign-banner>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { TemplateType } from "shared-utils/enums/masterTemplate";
import bus from "@/bus";
import { BannerActionEvents, BannerEvents } from "@/enums/banners";
import BannerPreview from "@/components/Previews/BannerPreview";
import BannerInfoPoptip from "@/components/Campaign/BannerInfoPoptip";
import BannerStatusIndicator from "@/components/Campaign/BannerStatusIndicator";
import LibraryBannerActionItemsDropdown from "@/components/Campaign/LibraryBannerActionItemsDropdown";
import SetBannerStatusDropdown from "@/components/Campaign/SetBannerStatusDropdown";
import deliverableMethodsMixin from "@/mixins/deliverableMethodsMixin";
import { DeliverableLibraryAction } from "@/store/modules/deliverableLibrary";
import { formatGroupName } from "@/utils";
import languageUtils from "@/utils/languages";
import scalingBannerMixin from "@/mixins/scalingBannerMixin";
import ResizableCampaignBanner from "@/components/Campaign/ResizableCampaignBanner";

export default {
    components: {
        ResizableCampaignBanner,
        BannerPreview,
        BannerInfoPoptip,
        BannerStatusIndicator,
        LibraryBannerActionItemsDropdown,
        SetBannerStatusDropdown
    },

    filters: {
        formatGroupName
    },
    mixins: [deliverableMethodsMixin, scalingBannerMixin],
    props: {
        deliverable: {
            type: Object,
            required: true
        }
    },

    computed: {
        campaignId() {
            return this.$store.state.route.params.campaignId;
        },

        clientId() {
            return this.$store.state.route.params.clientId;
        },

        isHTML5() {
            return this.deliverable.masterTemplate.adType === TemplateType.HTML5;
        },

        languageLabel() {
            return languageUtils.localeToFriendlyName(this.deliverable.language);
        },

        masterTemplate() {
            return this.deliverable.masterTemplate;
        },

        reportingLabel() {
            return this.deliverable.reportingLabel;
        },

        summaryGroupValues() {
            const allGroups = this.$store.state.campaign.normalized.editableGroupIds.reduce(
                (acc, cur) => Object.assign(acc, { [cur]: "None" }),
                {}
            );
            let combinationGroups = {};
            if (this.deliverable.combination) {
                let { combination } = this.deliverable;
                if (!Array.isArray(combination)) {
                    combination = [combination];
                }
                const reductor = (acc, combinationGroup) => {
                    const groupName = Object.keys(combinationGroup).pop();
                    return Object.assign(acc, {
                        [groupName]:
                            this.$store.state.campaign.editableGroupValues[combinationGroup[groupName].groupValueId]
                                .value
                    });
                };
                combinationGroups = combination.reduce(reductor.bind(this), {});
            }
            return { ...allGroups, ...combinationGroups };
        }
    },
    methods: {
        emitStatusHasBeenUpdated() {
            this.$emit("statusHasBeenUpdated");
        },

        onBannerLoaded() {
            this.$emit(BannerEvents.BannerLoaded);
        },

        refreshBanner() {
            bus.$emit(BannerActionEvents.BannerRefresh, this.deliverable.idHash);
        },
        showDrawer() {
            this.$store.dispatch(DeliverableLibraryAction.SetDrawerIsVisible, true);
            this.$store.dispatch(DeliverableLibraryAction.SetDrawerDeliverables, [this.deliverable]);
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.library-banner {
    border: 1px solid $grey3;
    box-sizing: content-box;
    display: flex;
    width: 100%;
    overflow: hidden;
    flex-direction: column;
    position: relative;

    &--resizable {
        resize: both;
        overflow: auto;
    }
}

.library-banner__banner-container {
    flex: 1 1;
    align-items: center;
    display: flex;
    font-size: 0;
    justify-content: center;
    position: relative;

    &-checkbox--resizable {
        width: 100%;
        height: 100%;
    }

    .ivu-checkbox {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
    }

    .ivu-checkbox-inner {
        height: 20px;
        margin: $spacing-small 0 0 $spacing-small;
        width: 20px;

        &:after {
            height: 14px;
            left: 8px;
        }
    }

    .ivu-checkbox-wrapper {
        margin: 0;
    }
}

.library-banner__footer {
    flex: 0 0 40px;
    align-items: center;
    background: $white;
    display: flex;
    justify-content: space-between;
    max-width: 100%;
}

.library-banner__title {
    color: $darktheme20;
    flex: 1 0;
    font-size: $font-size-small;
    min-width: 0;
}
</style>
